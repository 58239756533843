<template>
    <div>
        <div class="top">
            <div class="sha">
                <div class="le">
                    <img src="https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8041977422307179810.jpg"
                        alt="">
                </div>
                <div class="you">
                    <div class="tuImg" v-for="item, index in tuList" :key="index">
                        <img style="width: 100%; height: 100%;" :src="item" alt="">
                    </div>
                </div>
            </div>
            <div class="xia">
                <div class="it scroll-up">拥有 <span style="color:#FFBB00 ;font-weight: 400;font-size: 27px;">软件著作权</span>
                    12个，当前已通过
                    <span style="color:#FFBB00 ;font-weight: 400;font-size: 27px;">国家科技型中小企业</span>
                    申请
                </div>
                <div class="it scroll-up">以软件技术为核心，硬件设备为支撑，移动互联为支柱</div>
                <div class="it scroll-up">拥有资深团队以及专业研发人员为客户产品保驾护航</div>
                <div class="it scroll-up">根据需求为客户定制 <span
                        style="color:#FFBB00 ;font-weight: 400;font-size: 27px;">精细化</span>
                    产品，利用自主低代码平台实线快速开发，缩短开发周期及成本</div>
            </div>
        </div>
        <div :class="scrollFn">客户案例</div>
        <div class="ke">
            <div class="tupian" v-for="item, index in photoList" :key="index">
                <img style="width: 100%; height: 100%;" :src="item" alt="">
            </div>
        </div>
        <div :class="zhuFn">主流开发语言：Java、PHP、Python、C++、HTML5、JavaScript、Vue、React、
            Angular、Jquery等开发语言和框架，可根据实际开发需求进行调整实施
        </div>
        <div :class="zhuFn" style="margin-top: 10px;">技术特色：架构分层、冗余机制、分隔、异步、分布式、迭代升级、可扩展性、自动化、集群化、缓存处理</div>
        <div class="xubox">
            <div class="xutop">
                <div class="xuItem" style="line-height: 65px;">客户需求</div>
                <div class="xuItem"><img class="imgxu" src="../../assets/yuan2.png" alt=""></div>
                <div class="xuItem" style="line-height: 65px;">技术研发</div>
                <div class="xuItem"><img class="imgxu" src="../../assets/yuan4.png" alt=""></div>
                <div class="xuItem" style="line-height: 65px;">运维管理</div>
            </div>
            <div class="jiantou">
                <img style="width: 100%;height: 100%;" src="../../assets/lujing.png" alt="">
            </div>
            <div class="xutop">
                <div class="xuItem"><img class="imgxu" src="../../assets/yuan1.png" alt=""></div>
                <div class="xuItem" style="line-height: 30px;">方案策划</div>
                <div class="xuItem"><img class="imgxu" src="../../assets/yuan3.png" alt=""></div>
                <div class="xuItem" style="line-height: 30px;">品控交付</div>
                <div class="xuItem"><img class="imgxu" src="../../assets/yuan5.png" alt=""></div>
            </div>
        </div>
        <div :class="lianFn">联系我们</div>
        <div :class="xuanFn">感谢选择易添信息，欢迎提出需求或合作</div>
        <div class="lanbox">
            <el-form style="width: 85%;margin: 0 auto;padding-top: 40px;" :model="ruleForm" :rules="rules" ref="ruleForm"
                label-width="80px" label-position="left" class="demo-ruleForm">
                <el-row class="row">
                    <el-col class="col" :span="11">
                        <el-form-item label="姓名" prop="customerName">
                            <el-input v-model="ruleForm.customerName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="col" :span="11" style="margin-left: 8.2%;">
                        <el-form-item label="企业名称">
                            <el-input v-model="ruleForm.companyName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="row">
                    <el-col class="col" :span="11">
                        <el-form-item label="电话" prop="customerPhone">
                            <el-input v-model="ruleForm.customerPhone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="col" :span="11" style="margin-left: 8.2%;">
                        <el-form-item label="邮箱">
                            <el-input v-model="ruleForm.customerEmail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-col :span="24" style="background-color: #FFFFFF;">
                        <el-form-item labelWidth="110px" label="项目落地地点" prop="projectProvince">
                            <el-row>
                                <el-col :span="12">
                                    <el-select @change="changeCity" v-model="ruleForm.projectProvince" placeholder="请选择省">
                                        <el-option v-for="item, index in provinList" :key="index" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="12">
                                    <el-select v-model="ruleForm.projectcity" placeholder="请选择市">
                                        <el-option v-for="item, index in cityList" :key="index" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-col :span="24" style="background-color: #FFFFFF;">
                        <el-form-item label="合作需求" prop="customerDemand">
                            <el-row>
                                <el-col :span="24">
                                    <el-select v-model="ruleForm.customerDemand" placeholder="请选择">
                                        <el-option label="交流充电桩咨询与购买" value="0"></el-option>
                                        <el-option label="直流充电桩咨询与购买" value="1"></el-option>
                                        <el-option label="建站运营" value="2"></el-option>
                                        <el-option label="企业投资" value="3"></el-option>
                                        <el-option label="直流充电桩经销代理" value="4"></el-option>
                                        <el-option label="能源管理合作" value="5"></el-option>
                                        <el-option label="企业业务了解" value="6"></el-option>
                                        <el-option label="APP合作" value="7"></el-option>
                                        <el-option label="技术交流" value="8"></el-option>
                                        <el-option label="平台互联" value="9"></el-option>
                                        <el-option label="能源产品咨询与购买" value="10"></el-option>
                                        <el-option label="其他" value="11"></el-option>
                                        <el-option label="交流充电桩经销代理" value="12"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-col :span="24" style="background-color: #FFFFFF;">
                        <el-form-item label="需求描述" prop="customerDemandRemark">
                            <el-input v-model="ruleForm.customerDemandRemark" type="textarea" :rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div style="width: 70%;margin:0 auto;margin-top: 40px;display: flex;justify-content: space-between;">
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button type="primary" @click="resetForm('ruleForm')">取消</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            tuList: [
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8422733273362920226.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8686068543004904646.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8429320738577518752.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image5183410457156049532.jpg'
            ],
            scrollEffect: true,
            scrollEffect1: false,
            scrollEffect2: false,
            scrollEffect3: false,
            photoList: [
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image6931875193835414119.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image3254614518447670351.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image4953393833830182733.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image3266895380914593402.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image204086971221970405.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image2121276746112225388.jpg"
            ],
            ruleForm: {},
            rules: {
                customerName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                customerPhone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                ],
                projectProvince: [
                    { required: true, message: '请选择项目落地地点', trigger: 'change' },
                ],
                customerDemand: [
                    { required: true, message: '请选择合作需求', trigger: 'change' },
                ],
                customerDemandRemark: [
                    { required: true, message: '请选择需求描述', trigger: 'change' },
                ],
            },
            provinList: [],
            cityList: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.provinFn()
    },
    methods: {
        changeCity(e) {
            // 定义变量 pid
            let pid = e;
            // 在 Axios 请求中使用 pid 变量
            axios.get(`https://mqtt.yt202.com/yitian/city/list?pid=${pid}`)
                .then(res => {
                    this.cityList = res.data.data.records;
                    this.ruleForm.projectcity = this.cityList[0].id
                })
                .catch(error => {
                    console.error(error);
                });
        },
        handleScroll() {
            if (window.scrollY > 90) {
                this.scrollEffect = true;
            } else {
                this.scrollEffect = false;
            }
            if (window.scrollY > 318) {
                this.scrollEffect1 = true;
            } else {
                this.scrollEffect1 = false;
            }
            if (window.scrollY > 510) {
                this.scrollEffect2 = true;
            } else {
                this.scrollEffect2 = false;
            }
            if (window.scrollY > 646) {
                this.scrollEffect3 = true;
            } else {
                this.scrollEffect3 = false;
            }
        },
        provinFn() {
            axios.get('https://mqtt.yt202.com/yitian/city/list?pid=0')
                .then(res => {
                    this.provinList = res.data.data.records
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.ruleForm);
                    axios.post('https://mqtt.yt202.com/yitian/customer-demand', {
                        ...this.ruleForm
                    }).then(res => res.data)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success'
                                });
                                this.$refs[formName].resetFields();
                                this.ruleForm = {}
                            }
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    computed: {
        scrollFn() {
            if (this.scrollEffect == false) {
                return 'dang'
            } else {
                return 'dang scroll-up'
            }
        },
        zhuFn() {
            if (this.scrollEffect1 == false) {
                return 'zhu'
            } else {
                return 'zhu scroll-up'
            }
        },
        xuanFn() {
            if (this.scrollEffect3 == false) {
                return 'xuan'
            } else {
                return 'xuan scroll-up'
            }
        },
        lianFn() {
            if (this.scrollEffect2 == false) {
                return 'lian'
            } else {
                return 'lian scroll-up'
            }
        }
    }
}
</script>

<style lang="less" scoped>
.top {
    width: 100%;
    min-height: 675px;
    background: url(../../assets/ye.png) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* 增加内边距以适应小屏幕 */

    .sha {
        width: 100%;
        max-width: 1200px;
        /* 设置最大宽度 */
        margin: 0 auto;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        /* 允许在小屏幕上换行 */

        .le {
            width: 50%;
            height: 95%;
        }

        .le>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .you {
            display: flex;
            flex-wrap: wrap;
            width: 45%;
            justify-content: center;
            align-content: center;
        }

        .tuImg {
            // width: 150px;
            // height: 113px;
            // border-radius: 4px;
            // margin: 10px;
            width: calc(50% - 20px);
            /* 每行两个图片，减去两边的间距 */
            height: 113px;
            border-radius: 4px;
            margin: 10px;
            box-sizing: border-box;
        }
    }

    .xia {
        width: 90%;
        margin: 40px auto 0;
        font-size: 26px;
        font-weight: 400;
        color: #9E9E9E;
        text-align: left;

        .it {
            width: 100%;
            margin-top: 15px;
        }
    }
}

.dang {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.lian {
    margin-top: 80px;
    margin-bottom: 18px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #355E89;
}

.xuan {
    font-size: 16px;
    color: #9E9E9E;
    text-align: center;
}

@keyframes scrollAnimation {
    from {
        transform: translateY(100px);
    }

    to {
        transform: translateY(0);
        /* 向上滚动 20px */
    }
}

.scroll-up {
    animation: scrollAnimation 0.3s ease forwards;
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0s;
}

.ke {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .tupian {
        width: 186px;
        height: 77px;
        border-radius: 6px;
    }
}

.zhu {
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
    font-weight: normal;
    line-height: 35px;
    letter-spacing: 0px;
    color: #757575;
    margin-top: 40px;
    text-indent: 2em
}

.xubox {
    width: 90%;
    margin: 0 auto;
    margin-top: 60px;

    .xutop {
        width: 93%;
        height: 46px;
        display: flex;
        margin-left: 2%;
        justify-content: space-between;
        color: #355E89;
        font-size: 20px;
        font-weight: normal;
        align-items: flex-end;

        .xuItem {
            width: 100px;
            height: 46px;
            line-height: 46px;
            text-align: center;

            .imgxu {
                width: 46%;
                height: 100%;
            }
        }
    }

    .jiantou {
        width: 100%;
        height: 30px;
    }
}

.lanbox {
    width: 50%;
    height: 500px;
    background: #355E89;
    margin: 0 auto;
    margin-top: 20px;

    .row {
        margin-top: 20px;

        .col {
            background-color: #FFFFFF;
        }
    }

    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }

    /deep/ .el-form-item__label {
        color: #9E9E9E !important;
        padding: 0px 0px 0px 10px !important;
    }

    /deep/ .el-input__inner {
        border: none !important;
    }

    /deep/ .el-textarea__inner {
        border: none !important;
    }
}
</style>