<!-- <template>
  <div class="home">
    <div class="top">
      <div class="left">
        <div class="tu">
          <img src="../assets/logo.png" alt="">
        </div>
        易添信息
      </div>
      <div class="right">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#172E46" text-color="#fff" active-text-color="#fff">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">业务范畴</el-menu-item>
          <el-menu-item index="3">关于我们</el-menu-item>
        </el-menu>
        <div class="phone">
          <div class="zuophone">
            <img src="../assets/phone.png" alt="">
          </div>
          19941523480
        </div>
      </div>
    </div>
    <firstPage v-if="activeIndex == '1'"></firstPage>
    <businessPage v-else-if="activeIndex == '2'"></businessPage>
    <concerPage v-else-if="activeIndex == '3'"></concerPage>
    <div class="bottom">
      <div class="shang">
        <div class="item1">
          <div class="hang1">站内导航</div>
          <div class="hang2" style="cursor: pointer;" @click="activeIndex = '1'">首页</div>
          <div class="hang2" style="cursor: pointer;" @click="activeIndex = '2'">业务范畴</div>
          <div class="hang2" style="cursor: pointer;" @click="activeIndex = '3'">关于我们</div>
        </div>
        <div class="item1">
          <div class="hang1">联系我们</div>
          <div class="hang2">19941523480（联系电话）</div>
          <div class="hang2">邮箱：colin@yt202.com</div>
          <div class="hang2">地址：南京市栖霞区尧化街道仙尧路2号2号楼</div>
        </div>
        <div class="item1">
          <div class="matu">
            <img src="../assets/ma.png" alt="">
          </div>
          <div style="text-align: center; color: #FFFFFF;font-size: 18px;margin-top: 10px;">扫码了解更多</div>
        </div>
      </div>
      <div class="xia">
        <div>Copyright 2024 All Rights Reserved 苏ICP备2024063644号-1</div>
        <div>版权所有 南京易添信息技术有限公司</div>
      </div>
    </div>
  </div>
</template> -->
<template>
  <div class="home">
    <!-- 悬浮的新年小盒子 -->
    <div class="new-year-box" @click="goFn">
      <div class="new-year-content">
        <span>🎉 新年快乐！</span>
        <span>点击免费制作新年放假海报</span>
      </div>
    </div>
    <!-- 原有代码 -->
    <div class="top">
      <div class="left">
        <div class="tu">
          <img src="../assets/logo.png" alt="">
        </div>
        易添信息
      </div>
      <div class="right">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#172E46" text-color="#fff" active-text-color="#fff">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">业务范畴</el-menu-item>
          <el-menu-item index="3">关于我们</el-menu-item>
        </el-menu>
        <div class="phone">
          <div class="zuophone">
            <img src="../assets/phone.png" alt="">
          </div>
          19941523480
        </div>
      </div>
    </div>
    <firstPage v-if="activeIndex == '1'"></firstPage>
    <businessPage v-else-if="activeIndex == '2'"></businessPage>
    <concerPage v-else-if="activeIndex == '3'"></concerPage>
    <div class="bottom">
      <div class="shang">
        <div class="item1">
          <div class="hang1">站内导航</div>
          <div class="hang2" style="cursor: pointer;" @click="activeIndex = '1'">首页</div>
          <div class="hang2" style="cursor: pointer;" @click="activeIndex = '2'">业务范畴</div>
          <div class="hang2" style="cursor: pointer;" @click="activeIndex = '3'">关于我们</div>
        </div>
        <div class="item1">
          <div class="hang1">联系我们</div>
          <div class="hang2">19941523480（联系电话）</div>
          <div class="hang2">邮箱：colin@yt202.com</div>
          <div class="hang2">地址：南京市栖霞区尧化街道仙尧路2号2号楼</div>
        </div>
        <div class="item1">
          <div class="matu">
            <img src="../assets/ma.png" alt="">
          </div>
          <div style="text-align: center; color: #FFFFFF;font-size: 18px;margin-top: 10px;">扫码了解更多</div>
        </div>
      </div>
      <div class="xia">
        <div>Copyright 2024 All Rights Reserved 苏ICP备2024063644号-1</div>
        <div>版权所有 南京易添信息技术有限公司</div>
      </div>
    </div>
  </div>
</template>


<script>
import firstPage from './components/index'
import businessPage from './components/business'
import concerPage from './components/concer'
export default {
  name: 'HomeView',
  components: {
    firstPage,
    businessPage,
    concerPage
  },
  data() {
    return {
      activeIndex: '1'
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key
    },
    goFn() {
      window.open('https://yt202.com/template/', '_blank');
    }
  }
}
</script>

<style lang="less">
.top {
  display: flex;
  width: 100%;
  height: 64px;
  background: #172E46;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 40px;
  font-size: 18px;

  .tu {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }

  .tu>img {
    width: 100%;
    height: 100%;
  }
}

.right {
  display: flex;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}

.phone {
  display: flex;
  align-items: center;
  color: #fff;
  margin-right: 40px;
  margin-left: 40px;
  font-size: 14px;

  .zuophone {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .zuophone>img {
    width: 100%;
    height: 100%;
  }
}

.bottom {
  width: 100%;
  height: 250px;
  margin-top: 20px;
  background-color: #172e46;

}

.shang {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .item1 {
    .hang1 {
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
    }

    .hang2 {
      font-size: 15px;
      font-weight: normal;
      line-height: 40px;
      letter-spacing: 0.02em;
      color: #9E9E9E;
    }

    .matu {
      width: 120px;
      height: 120px;
    }

    .matu>img {
      width: 100%;
      height: 100%;
    }
  }
}

.xia {
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
}

.new-year-box {
  position: fixed;
  bottom: 40%;
  right: 20px;
  z-index: 1000;
  background-color: #d32f2f;
  /* 红色背景 */
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: float 3s ease-in-out infinite;
  cursor: pointer;
}

.new-year-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffd700;
  /* 金色文字 */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.new-year-content span {
  margin: 5px 0;
}

/* 悬浮动画 */
@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

/* 烟花效果（可选） */
.new-year-box::before,
.new-year-box::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffd700;
  border-radius: 50%;
  animation: firework 1s ease-out infinite;
}

.new-year-box::before {
  top: -10px;
  left: 20%;
}

.new-year-box::after {
  top: -10px;
  right: 20%;
}

@keyframes firework {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
