var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"new-year-box",on:{"click":_vm.goFn}},[_vm._m(0)]),_c('div',{staticClass:"top"},[_vm._m(1),_c('div',{staticClass:"right"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":"#172E46","text-color":"#fff","active-text-color":"#fff"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"1"}},[_vm._v("首页")]),_c('el-menu-item',{attrs:{"index":"2"}},[_vm._v("业务范畴")]),_c('el-menu-item',{attrs:{"index":"3"}},[_vm._v("关于我们")])],1),_vm._m(2)],1)]),(_vm.activeIndex == '1')?_c('firstPage'):(_vm.activeIndex == '2')?_c('businessPage'):(_vm.activeIndex == '3')?_c('concerPage'):_vm._e(),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"shang"},[_c('div',{staticClass:"item1"},[_c('div',{staticClass:"hang1"},[_vm._v("站内导航")]),_c('div',{staticClass:"hang2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.activeIndex = '1'}}},[_vm._v("首页")]),_c('div',{staticClass:"hang2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.activeIndex = '2'}}},[_vm._v("业务范畴")]),_c('div',{staticClass:"hang2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.activeIndex = '3'}}},[_vm._v("关于我们")])]),_vm._m(3),_vm._m(4)]),_vm._m(5)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-year-content"},[_c('span',[_vm._v("🎉 新年快乐！")]),_c('span',[_vm._v("点击免费制作新年放假海报")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_vm._v(" 易添信息 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone"},[_c('div',{staticClass:"zuophone"},[_c('img',{attrs:{"src":require("../assets/phone.png"),"alt":""}})]),_vm._v(" 19941523480 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item1"},[_c('div',{staticClass:"hang1"},[_vm._v("联系我们")]),_c('div',{staticClass:"hang2"},[_vm._v("19941523480（联系电话）")]),_c('div',{staticClass:"hang2"},[_vm._v("邮箱：colin@yt202.com")]),_c('div',{staticClass:"hang2"},[_vm._v("地址：南京市栖霞区尧化街道仙尧路2号2号楼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item1"},[_c('div',{staticClass:"matu"},[_c('img',{attrs:{"src":require("../assets/ma.png"),"alt":""}})]),_c('div',{staticStyle:{"text-align":"center","color":"#FFFFFF","font-size":"18px","margin-top":"10px"}},[_vm._v("扫码了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xia"},[_c('div',[_vm._v("Copyright 2024 All Rights Reserved 苏ICP备2024063644号-1")]),_c('div',[_vm._v("版权所有 南京易添信息技术有限公司")])])
}]

export { render, staticRenderFns }