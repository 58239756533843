<template>
    <div>
        <div class="top1">
            <div class="gong scroll-up">
                <span style="display: inline-block;">公司介绍</span>
            </div>
            <div class="content">
                <div class="nan scroll-up">
                    南京易添信息技术有限公司是一家专注于互联网研发的高科技企业。随着电动汽车的普及，充电桩需求迅猛增长，而高效的充电桩管理系统成为行业发展的关键。公司拥有一支经验丰富的研发团队，掌握大数据、物联网、云计算等前沿技术，能够为客户量身定制充电桩平台解决方案，涵盖设备管理、用户接口、数据分析和安全监控等方面。
                </div>
                <div class="nan scroll-up">
                    在设备管理方面，南京易添信息技术有限公司开发的系统能够实时监控充电桩的状态，包括在线状态、故障报警等，支持远程诊断与维护，提升运营效率。用户接口方面，公司提供用户友好的移动应用和网站，方便用户查找充电桩、预约、支付、实时监控充电进度等，提升用户体验。
                </div>
                <div class="nan scroll-up">
                    数据分析是充电桩平台的重要组成部分，公司利用先进的数据处理技术，对充电数据进行深度分析，为企业提供决策支持，优化充电桩布局和运营策略。此外，系统的安全监控功能通过多层次的身份认证、数据加密和访问控制，确保设备和数据的安全，保护用户隐私。
                </div>
                <div class="nan scroll-up">
                    南京易添信息技术有限公司秉承“创新驱动，客户至上”的理念，致力于通过高效、可靠的服务，帮助企业提升竞争力，优化运营成本，实现充电桩业务的智能化和可持续发展。无论是充电桩的硬件管理还是用户服务，公司都以客户需求为导向，提供全方位的技术支持和解决方案，助力企业在充电桩行业中取得领先地位。
                </div>
            </div>
        </div>
        <div class="ruan">软著证书</div>
        <div style="width: 60%;margin: 0 auto;margin-top: 20px;height: 350px;">
            <el-carousel :autoplay="false" arrow="always" height="350px">
                <el-carousel-item style="text-align: center;" v-for="item, index in photoList" :key="index">
                    <img :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div style="width: 96%;margin: 0 auto;margin-top: 60px;">
            <el-carousel :interval="4000" type="card" height="400px">
                <el-carousel-item v-for="item, index in photoList1" :key="index">
                    <img style="width: 100%; height: 100%;" :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div :class="lianFn">联系我们</div>
        <div :class="xuanFn">感谢选择易添信息，欢迎提出需求或合作</div>
        <div class="lanbox">
            <el-form style="width: 85%;margin: 0 auto;padding-top: 40px;" :model="ruleForm" :rules="rules" ref="ruleForm"
                label-width="80px" label-position="left" class="demo-ruleForm">
                <el-row class="row">
                    <el-col class="col" :span="11">
                        <el-form-item label="姓名" prop="customerName">
                            <el-input v-model="ruleForm.customerName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="col" :span="11" style="margin-left: 8.2%;">
                        <el-form-item label="企业名称">
                            <el-input v-model="ruleForm.companyName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="row">
                    <el-col class="col" :span="11">
                        <el-form-item label="电话" prop="customerPhone">
                            <el-input v-model="ruleForm.customerPhone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="col" :span="11" style="margin-left: 8.2%;">
                        <el-form-item label="邮箱">
                            <el-input v-model="ruleForm.customerEmail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-col :span="24" style="background-color: #FFFFFF;">
                        <el-form-item labelWidth="110px" label="项目落地地点" prop="projectProvince">
                            <el-row>
                                <el-col :span="12">
                                    <el-select @change="changeCity" v-model="ruleForm.projectProvince" placeholder="请选择省">
                                        <el-option v-for="item, index in provinList" :key="index" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="12">
                                    <el-select v-model="ruleForm.projectcity" placeholder="请选择市">
                                        <el-option v-for="item, index in cityList" :key="index" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-col :span="24" style="background-color: #FFFFFF;">
                        <el-form-item label="合作需求" prop="customerDemand">
                            <el-row>
                                <el-col :span="24">
                                    <el-select v-model="ruleForm.customerDemand" placeholder="请选择">
                                        <el-option label="交流充电桩咨询与购买" value="0"></el-option>
                                        <el-option label="直流充电桩咨询与购买" value="1"></el-option>
                                        <el-option label="建站运营" value="2"></el-option>
                                        <el-option label="企业投资" value="3"></el-option>
                                        <el-option label="直流充电桩经销代理" value="4"></el-option>
                                        <el-option label="能源管理合作" value="5"></el-option>
                                        <el-option label="企业业务了解" value="6"></el-option>
                                        <el-option label="APP合作" value="7"></el-option>
                                        <el-option label="技术交流" value="8"></el-option>
                                        <el-option label="平台互联" value="9"></el-option>
                                        <el-option label="能源产品咨询与购买" value="10"></el-option>
                                        <el-option label="其他" value="11"></el-option>
                                        <el-option label="交流充电桩经销代理" value="12"></el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-col :span="24" style="background-color: #FFFFFF;">
                        <el-form-item label="需求描述" prop="customerDemandRemark">
                            <el-input v-model="ruleForm.customerDemandRemark" type="textarea" :rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div style="width: 70%;margin:0 auto;margin-top: 40px;display: flex;justify-content: space-between;">
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button type="primary" @click="resetForm('ruleForm')">取消</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            photoList: [
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image3994784195755217249.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image7986025857215594395.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image6299435131429824368.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image6410340374315189949.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image6557394693456302653.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image6926779094794822861.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image2998919050350105260.jpg",
            ],
            photoList1: [
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image867521266486036021.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image1692594569865200218.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image7028216810211642619.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image6766601144196998875.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8101754471767769288.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image5221579458130621989.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image3085458281099869449.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8232303687742483512.jpg",
                "https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image563932292846156842.jpg",
            ],
            scrollEffect2: false,
            scrollEffect3: false,
            ruleForm: {},
            rules: {
                customerName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                customerPhone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                ],
                projectProvince: [
                    { required: true, message: '请选择项目落地地点', trigger: 'change' },
                ],
                customerDemand: [
                    { required: true, message: '请选择合作需求', trigger: 'change' },
                ],
                customerDemandRemark: [
                    { required: true, message: '请选择需求描述', trigger: 'change' },
                ],
            },
            provinList: [],
            cityList: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.provinFn()
    },
    methods: {
        changeCity(e) {
            // 定义变量 pid
            let pid = e;
            // 在 Axios 请求中使用 pid 变量
            axios.get(`https://mqtt.yt202.com/yitian/city/list?pid=${pid}`)
                .then(res => {
                    this.cityList = res.data.data.records;
                    this.ruleForm.projectcity = this.cityList[0].id
                })
                .catch(error => {
                    console.error(error);
                });
        },
        provinFn() {
            axios.get('https://mqtt.yt202.com/yitian/city/list?pid=0')
                .then(res => {
                    this.provinList = res.data.data.records
                })
                .catch(error => {
                    console.error(error);
                });
        },
        handleScroll() {
            if (window.scrollY > 1050) {
                this.scrollEffect2 = true;
            } else {
                this.scrollEffect2 = false;
            }
            if (window.scrollY > 1060) {
                this.scrollEffect3 = true;
            } else {
                this.scrollEffect3 = false;
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.ruleForm);
                    axios.post('https://mqtt.yt202.com/yitian/customer-demand', {
                        ...this.ruleForm
                    }).then(res => res.data)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success'
                                });
                                this.$refs[formName].resetFields();
                                this.ruleForm = {}
                            }
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    computed: {
        xuanFn() {
            if (this.scrollEffect3 == false) {
                return 'xuan'
            } else {
                return 'xuan scroll-up'
            }
        },
        lianFn() {
            if (this.scrollEffect2 == false) {
                return 'lian'
            } else {
                return 'lian scroll-up'
            }
        }
    }
}
</script>

<style lang="less" scoped>
.top1 {
    width: 100%;
    min-height: 690px;
    background: url(../../assets/tu.png) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* 内容居中 */

    .gong {
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        color: #000;
        text-align: center;
        margin-top: 40px;
    }

    .content {
        width: 80%;
        /* 控制内容宽度 */
        max-height: calc(690px - 120px);
        /* 如果内容超出高度，允许滚动 */
        margin-top: 20px;

        .nan {
            font-family: Alimama DongFangDaKai;
            font-size: 20px;
            font-weight: normal;
            color: #676767;
            line-height: 1.5;
            /* 行高调整为1.5倍，提升可读性 */
            text-indent: 2em;
            margin-bottom: 20px;
            /* 段落间距 */
        }
    }

}

.ruan {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin-top: 60px;
}

.lian {
    margin-top: 80px;
    margin-bottom: 18px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #355E89;
}

.xuan {
    font-size: 16px;
    color: #9E9E9E;
    text-align: center;
}

@keyframes scrollAnimation {
    from {
        transform: translateY(100px);
    }

    to {
        transform: translateY(0);
        /* 向上滚动 20px */
    }
}

.scroll-up {
    animation: scrollAnimation 0.3s ease forwards;
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0s;
}

.lanbox {
    width: 50%;
    height: 500px;
    background: #355E89;
    margin: 0 auto;
    margin-top: 20px;

    .row {
        margin-top: 20px;

        .col {
            background-color: #FFFFFF;
        }
    }

    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }

    /deep/ .el-form-item__label {
        color: #9E9E9E !important;
        padding: 0px 0px 0px 10px !important;
    }

    /deep/ .el-input__inner {
        border: none !important;
    }

    /deep/ .el-textarea__inner {
        border: none !important;
    }
}
</style>